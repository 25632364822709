.baseLayout {
    min-width: 320px;
    overflow: hidden;
    width: 100vw;
    max-width: 100vw;
}

.base-inner-header {
    position: relative;
    color: white;
    top: 0;
    left: 0;
    right: 0;
    min-height: 2.5rem;
    background-color: #E20030;
}


.base-header {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    /*max-height: 120px;*/
    font-size: 16px;
    padding-bottom: 0.5rem;
    border-bottom: #E20030 5px solid;
}

@media (max-width: 1024px) {
    .base-header {
        flex-direction: column;
    }

    .logoContainer {
        max-width: 25vw;
    }

    .logo {
        max-width: 80%;
    }

    .lower-link-container {
        margin-left: -3% !important;
        margin-right: 1% !important;

    }

    .lower-link-container > a {
        margin: 0 0.5rem

    }
}

.link-container {
    position: relative;
    right: 10%;
    display: inline-flex;
    float: right;
    margin-top: 0.5rem;
    width: 50%;
    max-width: 550px;
    flex-direction: row;
    justify-content: space-between;
}


.lower-link-container {
    display: inline-flex;
    margin: auto 5%;
    width: 100%;
    font-size: 1.25rem;
    font-weight: 600;
    color: #808080a1;
    font-family: Cambria, sans-serif;
    flex-direction: row;
    justify-content: space-around;
    overflow-x: visible;
}

.lower-link-container-mobile {

    width: 100%;
    font-size: 1.25rem;
    font-weight: 600;
    color: #808080a1;
    font-family: Cambria, sans-serif;
    margin: auto 5% auto -5%;

}

.lowerHeader {
    display: flex;
    justify-content: left;

}

.logoContainer {
    float: left;
    width: 30vw;
    min-width: 280px;
}

.logo {
    width: 100%;
    /*min-width: 340px;*/
    max-height: 120px;
    margin-bottom: -1rem;
}


:root {
    --main-container-width: 70%;
    --main-container-width-mobile: 90%;
    --footer-height: 100px;
    min-width: 100vw;
}

.sideContainer {
    width: calc((100% - var(--main-container-width)) / 2);
    max-width: calc(85% - 320px);
}

@media (max-width: 767px) {
    .sideContainer {
        width: calc((100% - var(--main-container-width-mobile)) / 2);
        max-width: calc(85% - 320px);
    }

    .mainContentContainer {
        min-width: 320px;
        width: var(--main-container-width-mobile);
    }
}

.sideContainer > div {
    box-shadow: none;
}

.mainContainer {
    width: 100vw;
    min-width: 320px;
    min-height: calc(100vh - 220px);
    display: flex;
    padding-bottom: 2rem;
    justify-content: space-between;
}

.mainContentContainer {
    min-width: 320px;
    width: var(--main-container-width);
}

@media (max-width: 767px) {
    .mainContentContainer {
        min-width: 320px;
        width: var(--main-container-width-mobile);
    }
}

.footer {
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #E20030;
    min-height: var(--footer-height);
    min-width: 320px;
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    padding: 2rem;
    color: white;
}

.footerLinks {
    display: inline-flex;
    margin: auto 0;

}

.rightSide {
    min-width: 600px;
    width: 50%;
    justify-content: space-evenly;
}

.footerLinks > :not(:first-child) {
    margin-left: 2rem;
}

.baseLayoutLinks {
    color: inherit !important;
    text-decoration: inherit !important;
}

.fadeInnLinks {
    /*transition: width 0.5s;*/
}

.layoutDivider {
    min-height: calc(100vh - var(--footer-height))
}


* {
    /*border:1px solid red !important;*/
}

.flagContainer {
    display: flex;
    flex-direction: row;
    max-width: 2rem;
}

.flagContainer i {
    cursor: pointer;
}


.flagContainerSideMenu {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.flagContainerSideMenu i {
    cursor: pointer;
}

.fbButtonContainer {
    top: -2px;
    position: relative;
}
