.date{
    float:right
}

.expandableClickable{
    font-weight: bold;
    align-content: end;

}

.expandable{
    max-height: 210px;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0.45, 0.05, 0.55, 0.95);
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
}
.mainExpandable{
    max-height: 410px;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0.45, 0.05, 0.55, 0.95);
}

.mainExpandable.expanded{
    max-height: 100%;
}

.expandable.expanded{
    max-height: 100%;
    display: unset;
    -webkit-line-clamp: unset;
    -webkit-box-orient: unset;
}

.floatRight{
    float: right;
}

.mLeft{
    margin-left: 0.3rem!important;
    margin-right: -0.3rem!important;
}

.loaderWrapper{
    padding-top: 2rem;
    position: relative;
    top: 10vh;
    opacity: 0;
}

.allbuttonSelected{
    /*border: #00b5ad solid 1px!important;*/
    border: #E20030 solid 1px!important;
    /*box-shadow: inset 0 0 3px #E20030!important;*/
    /*box-shadow: inset 3px 3px 5px 6px #E20030!important;*/
    box-shadow: 0 8px 6px -6px #E20030!important;
}

.isLoading{
    opacity: 100%;
}

.expandableClickable:hover{
    color: #E20030;
}

.projectDisplayContainer{
    display: flex;
    flex-direction: row;
    min-height: 200px ;
    flex-wrap: wrap;
    justify-content: space-between;
}

.imageWrapper {
    min-height: 100%;
}

.contentWrapper{
    display: flex;
    flex-direction: column;
    width: calc(100% - 440px);
    padding: 1rem;
    padding-left: 3rem;
    overflow: hidden;
    justify-content: space-between;
}
@media screen and (max-width: 1150px){
    .imageWrapper{
        width:100%;
        min-height: 390px;
    }
    .contentWrapper{
        width: 100%;
    }
}



