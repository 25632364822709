.carouselContainer{
    /*min-height: 25rem;*/
    /*height: 100%;*/
}

.clampIt > p{
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.mLeft{
    margin-left: 0.3rem!important;
    margin-right: -0.3rem!important;
}
.expandableWrapper{
    position: relative;
    box-shadow: 0 0 10px 1.5px gray;
    border-radius: 8px;
    /*padding: 0.75rem;*/
    height: 240px;
    /*max-height: 190px;*/
    overflow: hidden;
    transition: all 0.2s ease-in;
    background-color:rgb(241 227 170)

}

.carouselWrapper{
    margin-top: 2rem;
    width: 103%;
    position: relative;
}

.expandableWrapperText{
    opacity: 0;

}
.expandableWrapperTextExpanded{
    /*opacity: 100%;*/
    transition: opacity 0.1s ease-in;
    transition-delay: 0.2s;
}
.expandableWrapperExpanded{
    height: auto;
    max-height: 100%;
}
.expandable{
    overflow: hidden;
    transition: all 0.3s ease-out;
    height: auto;
    flex: 1;
    /*background-color: green;*/
    background-color: #f9f3e1;
    padding: 1rem;

}

.expandableCollapsed{
    flex: 0;
    /*background-color: #BBDAB1;*/
    /*padding: 0.5rem;*/
}

.title{
    font-weight: bold;
    /*height: 100px;*/
    height: 50px;
    padding: 1rem;
    margin-bottom: 1rem;
    /*font-size: 1.5rem;*/
}

.flexInfo{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.timePeriod{
    font-style: italic;
    font-weight: bold;
    height: 60px;
    padding: 0.5rem;
    font-size: 14px
}

.categories{
    font-style: italic;
    font-weight: bold;
    height: 60px;
    padding: 0.5rem;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.cardImage{
    height: 50%;
    width: 100%;
    /*margin-right: -1rem;*/
}
.cardImageWrapper{
    position: relative;
    background-size: cover;
    left: 0;
    right: 0;
    top: 0;
    /*height: 100px;*/
    background-position: 0 15%;
    background-repeat: no-repeat;
    height:180px;
}

.carouselLine{
    position: absolute;
    top: 10%;
    left: -25%;
    right: -25%;
    height: 25%;
    background-color: #E20030;
}
