.header-mainpage{
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 2rem;
}

.contentFader.show{
    opacity: 1;
    transition: opacity 0.75s;
}

.contentFader.hide{
    opacity: 0;
    /*transition: opacity 0.5s;*/
}

.titleWrapper{
    text-align: center;
    margin-bottom: 1rem;
    /*padding: 2rem 2rem 0 2rem;*/
}

.waveContainer{
    height: 100px;
    overflow: hidden;
    position: relative;
    bottom: -400px;
    stroke-width: 0.5rem;
    color: #E20030;
    left: -2vw;
    min-width: max(600px, 140vw);
}

.divider{
    min-height: 2px;
    box-shadow: 0 1px lightgrey;
    margin: 2rem;
}


.hero-image-container{
    /*width: 106vw;*/
    /*min-width: 400px;*/
    /*position: relative;*/
    /*left: -22%;*/
    /*background-size: cover;*/
    /*height: 50%;*/
    /*min-height: 500px;*/
    /*!*border: solid #F6EDCE;*!*/
    /*background-position: center;*/
    /*background-repeat: no-repeat;*/
    /*!*border-color: #A5001E;*!*/
    width: 106vw;
    min-width: 400px;
    position: relative;
    left: -22%;
    height: 50%;
    min-height: 500px;
    border-bottom: solid  #F6EDCE;
    background-position: 0 15%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    /*background-color: black;*/
    background-color: #f9f3e1;

}

.heroImage{
    height: 100%;
    width: max-content;
    position: absolute;
    min-height: 500px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

}

.heroImgImg{
    height: 500px;
    opacity: 0%;
}
