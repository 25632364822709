.ui.active.button:hover{
    background-color: white;
}


.not-mobile-button-section > div > .ui.big.basic.fluid.buttons > .ui.active.button:hover{
    background-color: white!important;
    }

.not-mobile-button-section > .ui.big.basic.vertical.buttons > .ui.active.button:hover{
    background-color: white!important;
    }

.mobile-button-section > .ui.big.basic.vertical.buttons > .ui.active.button:hover{
    background-color: white!important;
}

.mobile-button-section >  .ui.big.basic.vertical.buttons > .ui.active.button:hover{
    background-color: white!important;
}

