.header-mainpage{
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 2rem;
}

.contentFader.show{
    opacity: 1;
    transition: opacity 2s;
}

.blog-post .contentFader.show{
    background-color: #f9f3e1;
    box-shadow: 0px 2px 3px -1px;
}

.blog-post .contentFader > p{
 padding-right: 2rem;
}

.contentFader.hide{
    opacity: 0;
    transition: opacity 0.75s;
}


