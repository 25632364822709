div.pageContent  > figure > img{
    width: 100%;
 }

div.pageContent{
    padding-right: 2rem;
    padding-left: 2rem;
}


div.pageContent img{
   /*height: 200px;*/
}
    /*@import url("http://3.124.217.242/wp-content/themes/twentytwenty/style.css?ver=1.5");*/
/*!* Styling the columns block *!*/
/*.wp-block-columns {*/
/*    margin-bottom: 20px;*/
/*}*/

/*.wp-block-column {*/
/*    margin: 0 0 0 2%;*/
/*}*/

/*.wp-block-column:first-child {*/
/*    margin: 0;*/
/*}*/

/*@media only screen and (max-width: 768px) {*/

/*    !* Make the columns block responsive *!*/

/*    .wp-block-columns {*/
/*        display: initial;*/
/*    }*/

/*    .wp-block-columns .wp-block-column {*/
/*        margin: 0 0 10px;*/
/*    }*/

/*}*/


.alignwide{
    display: flex;
    flex-direction: row;
    padding:5px
}

.wp-block-column{
    width: 100%;
    margin: 5px;
}
